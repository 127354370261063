import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyB_CZ-CQoPnT21GV4XBiZNbFXoL_lFB-f8",
  authDomain: "calsync-703b7.firebaseapp.com",
  projectId: "calsync-703b7",
  storageBucket: "calsync-703b7.appspot.com",
  messagingSenderId: "62994778050",
  appId: "1:62994778050:web:50275a39c6b4112b03b85b",
  measurementId: "G-VL1V7V5RR6",
};

const app = firebase.initializeApp(firebaseConfig);

export const analytics = firebase.analytics();
export const auth = firebase.auth;
export const firestore = firebase.firestore();

export default app;
