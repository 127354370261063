import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";

import React, { useCallback, useContext } from "react"; // add {useCallback, useContext}
import { Redirect } from "react-router";
import app from "../services/firebase.js";
import { AuthContext } from "../helpers/Authentication.js";
import { signInWithGoogle } from "../helpers/auth";
import { Helmet } from "react-helmet";

import logo from "../logo.png"; // Tell webpack this JS file uses this image

import { Link } from "react-router-dom";
function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      style={{ paddingBottom: 20 }}
    >
      {"Copyright © "}
      {"CalSync - Made with love in Toronto! "} {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#272932",
  },
  smallContainer: {
    backgroundColor: "white",
  },
  paper: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: 50,
    background: "linear-gradient(270deg, #8743FF 0%, #4136F1 100%)",
    boxShadow: "0px 15px 30px rgba(20, 102, 204, 0.16)",
  },
  logo: {
    margin: theme.spacing(3, 0, 2),
  },
  logoImage: {
    width: 60,
  },
  googleButton: {
    height: 50,
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#DB4437",
    boxShadow: "0px 15px 30px rgba(20, 102, 204, 0.16)",
  },
  normalButton: {},
}));

export default function SignIn() {
  const classes = useStyles();
  let history = useHistory();

  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await app
          .auth()
          .signInWithEmailAndPassword(email.value, password.value);
        history.push("/");
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleGoogleLogin = useCallback(async (event) => {
    try {
      await signInWithGoogle();
    } catch (error) {
      console.log("Print error", error);
    }
  });

  const { currentUser } = useContext(AuthContext);
  if (currentUser) {
    return <Redirect to="/" />;
  }

  return (
    <div className={classes.container}>
      <Helmet>
        <style>{"body { background-color: #272932; }"}</style>
      </Helmet>

      <Container
        className={classes.smallContainer}
        component="main"
        maxWidth="sm"
      >
        <CssBaseline />
        <div className={classes.paper}>
          <div className={classes.logo}>
            <img className={classes.logoImage} src={logo} alt="logo" />
          </div>
          <Typography component="h1" variant="h4">
            Sign in to CalSync
          </Typography>
          <form onSubmit={handleLogin} className={classes.form} noValidate>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.googleButton}
              onClick={handleGoogleLogin}
            >
              Sign in with Google
            </Button>
            <hr
              style={{
                marginTop: 20,
                marginBottom: 20,
                backgroundColor: "#e7e7e7",
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                {/* <Link to="/forgot" variant="body2">
                  Forgot password?
                </Link> */}
              </Grid>
              <Grid item>
                <Link to="/signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
}
