import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import PrivateRoute from "./PrivateRoute";
import CalendarConfigure from "./pages/CalendarConfigure";
import { AuthProvider } from "./helpers/Authentication";
import "fontsource-roboto";
import "@fontsource/gothic-a1";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://db6ad0f895484c38b3e564b995d72cd4@o1073620.ingest.sentry.io/6073335",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

function App() {
  return (
    <AuthProvider>
      <Router>
        <div>
          <PrivateRoute exact path="/" component={Home} />
          <Route exact path="/login" component={SignIn} />
          <Route exact path="/signup" component={SignUp} />
          <Route
            exact
            path="/calendar/configure"
            component={CalendarConfigure}
          />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
