import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import * as Sentry from "@sentry/react";

import LoadingScreen from "../components/Loading";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import logo from "../logo.png"; // Tell webpack this JS file uses this image
import { useHistory } from "react-router-dom";

import { logout } from "../helpers/auth";
import { firestore } from "../services/firebase";

import { auth } from "../services/firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";

import EmptyState from "../components/EmptyState";
import SimpleCard from "../components/SimpleCard";
import HelpCenter from "../components/HelpCenter";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AuthContext } from "../helpers/Authentication";
import { addUserCalendarInfo } from "../helpers/users";
import { GoogleLogin } from "react-google-login";
const { OAuth2Client } = require("google-auth-library");

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logo: {},
  logoImage: {
    height: 25,
  },
  googleButton: {
    margin: theme.spacing(3, 0, 2),
    height: 50,
    background: "linear-gradient(270deg, #f05d5e 0%, #f05d5e 100%)",
    boxShadow: "0px 15px 30px rgba(20, 102, 204, 0.16)",
    color: "white",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function ButtonAppBar() {
  const authContext = useContext(AuthContext);
  const classes = useStyles();
  let history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const notify = (error) => {
    toast.error(error, {
      position: "bottom-center",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    Sentry.captureException(error);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [loading, setLoading] = React.useState(false);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const calendarsRef = firestore
    .collection("users")
    .doc(auth().currentUser.uid)
    .collection("calendars");
  const query = calendarsRef.orderBy("id", "asc").limitToLast(25);

  const [calendars] = useCollectionData(query, { idField: "id" });

  const responseGoogle = async (response) => {
    if (!response) {
      setLoading(false);
      notify("Failed to get calendar access from google");
      return;
    }

    if (response.error) {
      console.log("Failed to get access to calendar", response.error);
      notify(`Failed to get access to calendar with error: ${response.error}`);
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const oAuth2Client = new OAuth2Client(
        "1005164584143-vavaui4b9vp442f51taalp588233kbbc.apps.googleusercontent.com",
        "MsnMNdzbwx_nsnj_9A1ayXt4",
        process.env.REACT_APP_REDIRECT_URI
      );

      if (response && response.code) {
        const r = await oAuth2Client.getToken(response.code);
        oAuth2Client.setCredentials(r.tokens);
        console.log("Response", r);

        const calendar = await addUserCalendarInfo(r.tokens);
        if (!calendar) {
          setLoading(false);
          notify("Failed to get access to calendar with error");
          console.log("Failed to get access to calendar");
          return;
        }

        setLoading(false);
        history.push({
          pathname: "/calendar/configure",
          state: { calendar, calendars },
        });
      } else {
        console.log("Failed to get auth", response);
        notify("Failed to get access to calendar with error");
        return;
      }
    } catch (err) {
      console.log("Got error", err);
      notify("Failed to get access to calendar with error");
    }
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={logout}>Logout</MenuItem>
    </Menu>
  );

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ backgroundColor: "#272932" }}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <div className={classes.logo}>
              <img className={classes.logoImage} src={logo} alt="logo" />
            </div>
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {/* CalSync */}
          </Typography>

          <Typography style={{ marginRight: 20 }}>
            {authContext.currentUser.email}{" "}
          </Typography>

          <MenuItem onClick={handleProfileMenuOpen}>
            <Avatar alt="Remy Sharp" src={authContext.currentUser.photoURL} />
          </MenuItem>
        </Toolbar>
      </AppBar>
      {renderMenu}

      {false ? (
        <EmptyState />
      ) : (
        <Container maxWidth="sm" style={{ marginTop: 50 }}>
          <Typography
            component="h4"
            variant="h4"
            style={{ fontWeight: "600", marginBottom: 30, textAlign: "center" }}
          >
            Your Calendars
          </Typography>

          <div className="overflow-y-auto h-screen-90">
            <HelpCenter
              data={"msg"}
              showActions
              showTags
              picWidth={120}
              calendars={calendars}
            />
          </div>

          <ToastContainer
            position="bottom-center"
            autoClose={8000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />

          <div className="overflow-y-auto h-screen-90">
            {calendars &&
              calendars.map((msg) => (
                <div key={msg.id} style={{ marginTop: 10, marginBottom: 10 }}>
                  <SimpleCard
                    data={msg}
                    showActions
                    showTags
                    picWidth={120}
                    calendars={calendars}
                  />
                </div>
              ))}
          </div>

          <div style={{ padding: 8, paddingHorizontal: 15 }}>
            <GoogleLogin
              className={classes.googleButton}
              clientId="1005164584143-vavaui4b9vp442f51taalp588233kbbc.apps.googleusercontent.com"
              buttonText="Connect Calendar"
              fetchBasicProfile={false}
              scope="https://www.googleapis.com/auth/calendar.calendarlist.readonly https://www.googleapis.com/auth/calendar.events"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              responseType="code"
              prompt="consent"
              accessType="offline"
              ux_mode="redirect"
              redirect_uri={process.env.REACT_APP_REDIRECT_URI}
              render={(renderProps) => (
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.googleButton}
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  Connect another calendar
                </Button>
              )}
            />
          </div>
        </Container>
      )}
    </div>
  );
}
