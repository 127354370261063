import React from "react";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";

import CardMedia from "@material-ui/core/CardMedia";
import Chip from "@material-ui/core/Chip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core/styles";
import { updateUserCalendar } from "../helpers/users";
import CardActions from "@material-ui/core/CardActions";
import Tooltip from "@material-ui/core/Tooltip";

const cardStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    margin: "4%",
    padding: "2%",
    paddingBottom: "2%",
    boxShadow: "0px 15px 30px rgba(20, 102, 204, 0.16) !important",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
  },
  content: {
    flex: "1 0 auto",
    padding: 5,
    paddingBottom: "1px !important",
  },
  cover: {
    width: 120,
    height: 120,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  tag: {
    margin: 4,
    fontSize: "0.7rem",
    height: 24,
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  margin: {
    margin: theme.spacing(1),
    padding: 6,
    fontSize: 18,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  imageDiv: {
    width: 150,
    height: 150,
    alignItems: "center",
    alignContent: "space-around",
    display: "flex",
  },
  "MuiButton-label": {
    color: "white",
  },
}));

export default function SimpleCard({
  data,
  showActions,
  showTags,
  picWidth,
  width,
  calendars,
}) {
  const classes = cardStyles();
  let history = useHistory();

  const [state, setState] = React.useState({
    syncing: "syncing" in data ? data.syncing : true,
  });

  const handleChange = (event) => {
    console.log(event.target.name, event.target.checked);
    setState({ ...state, [event.target.name]: event.target.checked });

    const calendar = data;
    const syncing = event.target.checked;
    calendar.syncing = syncing;

    updateUserCalendar(calendar);
    console.log("Set calendar sync to", calendar, syncing);
  };

  const convertTimestamp = (date) => {
    if (!date) {
      return "";
    }

    const timestamp = date.toDate();
    var time = timestamp.toLocaleTimeString("en-US");
    return time;
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // const theme = useTheme();
  // const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card className={classes.root} style={{ width: width }}>
      <div className={classes.imageDiv}>
        <CardMedia
          className={classes.cover}
          image="/gmail.png"
          style={{
            width: picWidth,
            height: picWidth,
          }}
          title="Live from space album cover"
        />
      </div>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignContent: "space-between",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="subtitle1" color="textSecondary">
              {data.name ? data.name : "Calendar"}
            </Typography>

            <div>
              {showActions ? (
                <CardActions disableSpacing style={{ padding: 0 }}>
                  <Tooltip title="Edit sync configuration of calendar">
                    <IconButton
                      aria-label="edit"
                      className={classes.margin}
                      onClick={() => {
                        history.push({
                          pathname: "/calendar/configure",
                          state: { calendar: data, calendars },
                        });
                      }}
                    >
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Syncing flag">
                    <FormControlLabel
                      style={{ fontSize: 10 }}
                      control={
                        <Switch
                          size="small"
                          checked={state.syncing}
                          onChange={handleChange}
                          name="syncing"
                          color="primary"
                        />
                      }
                      // label="Syncing"
                      // labelPlacement="bottom"
                      // labelPlacement="bottom"
                    />
                  </Tooltip>
                </CardActions>
              ) : (
                <div />
              )}
            </div>
          </div>
          <Typography component="h6" variant="h6">
            {data.id}
          </Typography>

          {showTags ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "wrap",
                listStyle: "none",
                margin: 0,
                marginTop: 4,
              }}
            >
              <Chip
                className={classes.tag}
                label={
                  data.type ? capitalizeFirstLetter(data.type) : "Personal"
                }
                variant="outlined"
              />

              {data.lastSynced ? (
                <Chip
                  className={classes.tag}
                  label={`Last Sync: ${convertTimestamp(data.lastSynced)}`}
                  variant="outlined"
                />
              ) : (
                <div />
              )}
            </div>
          ) : (
            <div />
          )}
        </CardContent>
      </div>
    </Card>
  );
}
