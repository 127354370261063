import React, { useEffect, useState } from "react";
import app from "../services/firebase.js";
import { addUser } from "./users";
import LoadingScreen from "../components/Loading";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import "@fontsource/gothic-a1";
// import GothicA1 from "../fonts/Gothic_A1/GothicA1-Regular.ttf";
export const AuthContext = React.createContext();

// const gothicA1 = {
//   fontFamily: "GothicA1",
//   fontStyle: "normal",
//   fontDisplay: "swap",
//   fontWeight: 400,
//   src: `
//     local('GothicA1'),
//     local('GothicA1-Regular'),
//     url(${GothicA1}) format('ttf')
//   `,
//   unicodeRange:
//     "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
// };

// const theme = createMuiTheme({
//   typography: {
//     fontFamily: "GothicA1",
//   },
//   overrides: {
//     MuiCssBaseline: {
//       "@global": {
//         "@font-face": [gothicA1],
//       },
//     },
//   },
// });

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "Gothic A1",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  useEffect(() => {
    app.auth().onAuthStateChanged((user) => {
      setCurrentUser(user);
      setPending(false);
      console.log("User", user);

      if (user) {
        const userObj = {
          displayName: user.displayName,
          email: user.email,
          emailVerfied: user.emailVerified,
          photoURL: user.photoURL,
          uid: user.uid,
        };
        addUser(userObj);
      }
    });
  }, []);

  if (pending) {
    return <LoadingScreen />;
  }
  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </AuthContext.Provider>
  );
};
