import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useCallback } from "react"; // add {useCallback, useContext}
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import { Helmet } from "react-helmet";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import EventIcon from "@material-ui/icons/Event";
import Switch from "@material-ui/core/Switch";
import InfoIcon from "@material-ui/icons/Info";

import { updateUserCalendar } from "../helpers/users";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontWeight: "600",
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  infoIcon: {
    verticalAlign: "middle",
    color: "lightgray",
    fontSize: 20,
  },
  smallContainer: {
    backgroundColor: "white",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: 50,
    background: "linear-gradient(270deg, #8743FF 0%, #4136F1 100%)",
    boxShadow: "0px 15px 30px rgba(20, 102, 204, 0.16)",
  },
  logo: {
    margin: theme.spacing(3, 0, 2),
  },
  logoImage: {
    width: 60,
  },
  googleButton: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#DB4437",
  },
  formControl: {
    margin: theme.spacing(1.5),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  labels: {
    fontWeight: "600",
    color: "black",
    marginBottom: 4,
    fontSize: 18,
  },
  optionRow: { flexDirection: "row" },
}));

export default function SignIn({ history }) {
  const classes = useStyles();

  const [syncTo, setSyncTo] = React.useState(
    history.location.state.calendar.syncTo
      ? history.location.state.calendar.syncTo
      : []
  );

  const [syncConfig, setSyncConfig] = React.useState(
    history.location.state.calendar.syncConfig
  );
  const [calendarType, setCalendarType] = React.useState(
    history.location.state.calendar.type
      ? history.location.state.calendar.type
      : ""
  );

  const [calendarName, setCalendarName] = React.useState(
    history.location.state.calendar.name
      ? history.location.state.calendar.name
      : ""
  );

  const [state, setState] = React.useState({
    onlyWorkHours: history.location.state.calendar.onlyWorkHours
      ? history.location.state.calendar.onlyWorkHours
      : false,
    onlyPrefixed: history.location.state.calendar.onlyPrefixed
      ? history.location.state.calendar.onlyPrefixed
      : false,
  });

  const handleCheckboxChange = (event) => {
    console.log("Set event", event.target.name);
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleSyncChange = (event) => {
    // var { syncTo } = state;
    console.log("Sync is", syncTo);
    console.log("event", event.target.value, event.target.name);

    let syncToConfig = syncTo;
    if (event.target.checked) {
      syncToConfig.push(event.target.name);
    } else {
      const index = syncToConfig.indexOf(event.target.name);
      if (index > -1) {
        syncToConfig.splice(index, 1);
      }
    }
    syncToConfig = syncToConfig.filter((v, i, a) => a.indexOf(v) === i);
    console.log("New value of sync to", syncToConfig);
    setSyncTo(syncToConfig);
  };

  const { onlyWorkHours, onlyPrefixed } = state;

  const handleCalendarTypeChange = (event) => {
    setCalendarType(event.target.value);
  };

  const handleNameChange = (event) => {
    setCalendarName(event.target.value);
  };

  const handleSyncConfigChange = (event) => {
    console.log("Got sync config", event.target.value);
    setSyncConfig(event.target.value);
    console.log(history.location.state);
  };

  const handleCalendarConfigure = useCallback(
    async (
      event,
      syncConfig,
      calendarType,
      onlyPrefixed,
      onlyWorkHours,
      calendarName,
      syncTo
    ) => {
      event.preventDefault();

      const calendar = history.location.state.calendar;
      calendar.syncConfig = syncConfig;
      calendar.type = calendarType;
      calendar.onlyWorkHours = onlyWorkHours;
      calendar.onlyPrefixed = onlyPrefixed;
      calendar.syncing = true;
      calendar.name = calendarName;
      calendar.syncTo = syncTo;

      console.log("Got calendatTType", calendar, syncConfig, calendarType);

      await updateUserCalendar(calendar);
      history.push("/");
    },
    [history]
  );

  return (
    <Container
      className={classes.smallContainer}
      component="main"
      maxWidth="sm"
    >
      <Helmet>
        <style>{"body { background-color: #272932; }"}</style>
      </Helmet>

      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h2" variant="h4">
          Setup Calendar
        </Typography>

        <div style={{ width: "98%" }}>
          <div style={{ display: "flex", marginTop: 20 }}>
            <Chip
              icon={<EventIcon />}
              label={
                history.location.state.calendar.id
                  ? history.location.state.calendar.id
                  : "Calendar"
              }
              clickable
              color="primary"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </div>

          <form
            onSubmit={(event) =>
              handleCalendarConfigure(
                event,
                syncConfig,
                calendarType,
                onlyPrefixed,
                onlyWorkHours,
                calendarName,
                syncTo
              )
            }
            className={classes.form}
            noValidate
          >
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <TextField
                className={classes.formControl}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                label="Calendar Nickname"
                name="name"
                autoComplete="name"
                value={calendarName}
                // autoFocus
                onChange={handleNameChange}
              />
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Calendar Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={calendarType}
                  onChange={handleCalendarTypeChange}
                  label="CalendarType"
                >
                  <MenuItem value={"personal"}>Personal</MenuItem>
                  <MenuItem value={"work"}>Work</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div style={{ margin: 20 }}>
              <FormControl component="fieldset">
                <FormLabel component="legend" className={classes.labels}>
                  Sync Configuration
                </FormLabel>

                <RadioGroup
                  aria-label="gender"
                  name="config"
                  value={syncConfig}
                  onChange={handleSyncConfigChange}
                >
                  <div className={classes.optionRow}>
                    <FormControlLabel
                      value="hide-details"
                      control={<Radio />}
                      label="Hide Event Details - Show as busy block"
                    ></FormControlLabel>
                    <LightTooltip title="This option will hide details about the event when syncing">
                      <InfoIcon className={classes.infoIcon} />
                    </LightTooltip>
                  </div>

                  <div className={classes.optionRow}>
                    <FormControlLabel
                      value="show-details"
                      control={<Radio />}
                      label="Sync event details"
                    />
                    <LightTooltip title="This option will sync all details about the event when syncing">
                      <InfoIcon className={classes.infoIcon} />
                    </LightTooltip>
                  </div>
                </RadioGroup>
              </FormControl>
              <FormControl
                // error={error}
                component="fieldset"
                style={{
                  marginTop: "6%",
                }}
              >
                <FormLabel component="legend" className={classes.labels}>
                  Only sync events
                </FormLabel>
                <FormGroup>
                  <div className={classes.optionRow}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={onlyWorkHours}
                          onChange={handleCheckboxChange}
                          name="onlyWorkHours"
                        />
                      }
                      label="During work hours"
                    />
                    <LightTooltip title="This option will only sync this calendars events during 9am-5pm">
                      <InfoIcon className={classes.infoIcon} />
                    </LightTooltip>
                  </div>
                  <div className={classes.optionRow}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={onlyPrefixed}
                          onChange={handleCheckboxChange}
                          name="onlyPrefixed"
                        />
                      }
                      label="With keyword [sync] in title"
                    />
                    <LightTooltip title="Will only sync events you specifically add [Sync] in the title off">
                      <InfoIcon className={classes.infoIcon} />
                    </LightTooltip>
                  </div>
                </FormGroup>
              </FormControl>

              <div style={{ marginTop: 20 }}>
                <FormControl component="fieldset">
                  <FormLabel component="legend" className={classes.labels}>
                    Which calendars to sync to?
                  </FormLabel>
                  <FormGroup>
                    {history.location.state.calendars &&
                      history.location.state.calendars
                        .filter(
                          (obj) => obj.id !== history.location.state.calendar.id
                        )
                        .map((cal) => (
                          <FormControlLabel
                            control={
                              <Switch
                                checked={
                                  syncTo && syncTo.includes(cal.id)
                                    ? true
                                    : false
                                }
                                onChange={handleSyncChange}
                                name={cal.id}
                              />
                            }
                            label={cal.id}
                          />
                        ))}
                  </FormGroup>
                </FormControl>
              </div>
            </div>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Save
            </Button>
          </form>
        </div>
      </div>
    </Container>
  );
}
