import firebase from "firebase/app";
import { firestore } from "../services/firebase";
import { auth } from "../services/firebase.js";
import { getCalendars } from "../services/platform";

export const addUser = async (userObj) => {
  const { displayName, uid, photoURL, email } = userObj;
  const usersRef = firestore.collection("users").doc(uid);
  await usersRef.set({
    user: displayName,
    email: email,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    uid: uid,
    photoURL: photoURL,
  });
};

export const addUserCalendarInfo = async (token) => {
  const currentUser = auth().currentUser;
  const uid = currentUser.uid;

  const response = await getCalendars(uid, token);
  if (response && response.calendars) {
    const { calendars, error } = response;
    console.log("Got response", calendars);

    if (error) {
      console.log("Failed to get authentication for calendar", error);
      return null;
    }

    const primary = calendars.find((cal) => cal.primary);
    console.log("Primary", primary);

    const calendarId = primary.id;
    const calendarRef = firestore
      .collection("users")
      .doc(uid)
      .collection("calendars")
      .doc(calendarId);

    let calendar = {
      tokens: token,
    };
    calendar = { ...calendar, ...primary };

    console.log("Got calendar and uid", calendar, uid);
    await calendarRef.set(calendar);

    return calendar;
  }
};

export const updateUserCalendar = async (calendar) => {
  const currentUser = auth().currentUser;
  const uid = currentUser.uid;

  const calendarId = calendar.id;
  const calendarRef = firestore
    .collection("users")
    .doc(uid)
    .collection("calendars")
    .doc(calendarId);

  console.log("Got calendar and uid", calendar, uid);
  await calendarRef.update(calendar);
  return calendar;
};
