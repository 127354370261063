import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
// Tell webpack this JS file uses this image
import { useHistory } from "react-router-dom";

import Lottie from "react-lottie";
import animationData from "../lotties/45666-valentine-day-gift-calendar (1).json";

import { addUserCalendarInfo } from "../helpers/users";
import { GoogleLogin } from "react-google-login";
const { OAuth2Client } = require("google-auth-library");

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logo: {},
  logoImage: {
    width: 40,
  },
  googleButton: {
    margin: theme.spacing(3, 0, 2),
    height: 50,
    background: "linear-gradient(270deg, #f05d5e 0%, #f05d5e 100%)",
    boxShadow: "0px 15px 30px rgba(20, 102, 204, 0.16)",
    color: "white",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();
  let history = useHistory();

  const responseGoogle = async (response) => {
    console.log(response);
    const oAuth2Client = new OAuth2Client(
      "1005164584143-vavaui4b9vp442f51taalp588233kbbc.apps.googleusercontent.com",
      "MsnMNdzbwx_nsnj_9A1ayXt4",
      "https://us-central1-calsync-703b7.cloudfunctions.net/getCalendars"
    );

    const r = await oAuth2Client.getToken(response.code);
    oAuth2Client.setCredentials(r.tokens);
    console.log("Response", r);

    const calendar = await addUserCalendarInfo(r.tokens);
    if (!calendar) {
      console.log("Failed to get access to calendar");
      return;
    }

    const calendars = [];

    history.push({
      pathname: "/calendar/configure",
      state: { calendar, calendars },
    });
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="sm" style={{ marginTop: 50 }}>
        <Typography component="h3" variant="h3" style={{ marginBottom: 30 }}>
          Welcome to CalSync
        </Typography>

        <Typography component="h6" variant="h6" style={{ marginBottom: 30 }}>
          We created CalSync as a solution for a simple problem. We wanted our
          personal events to be synced to our work calendars while hiding the
          details. So we built it!
        </Typography>
        <div className="overflow-y-auto h-screen-90"></div>

        <Lottie
          options={defaultOptions}
          height={400}
          width={400}
          isStopped={false}
          isPaused={false}
        />

        <div style={{ padding: 20 }}>
          <GoogleLogin
            className={classes.googleButton}
            clientId="1005164584143-vavaui4b9vp442f51taalp588233kbbc.apps.googleusercontent.com"
            buttonText="Let's connect your first calendar"
            scope="https://www.googleapis.com/auth/calendar"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            redirectUri="http://localhost:3000/oauth/redirect"
            responseType="code"
            prompt="consent"
            accessType="offline"
            render={(renderProps) => (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.googleButton}
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                Let's connect your first calendar
              </Button>
            )}
          />
        </div>
      </Container>
    </div>
  );
}
