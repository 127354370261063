import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";

const cardStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    margin: "4%",
    padding: "2%",
    paddingBottom: "2%",
    boxShadow: "0px 15px 30px rgba(20, 102, 204, 0.16) !important",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
  },
  content: {
    flex: "1 0 auto",
    padding: 20,
  },
  cover: {
    width: 120,
    height: 120,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  tag: {
    margin: 4,
    fontSize: "0.7rem",
    height: 24,
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  margin: {
    margin: theme.spacing(1),
    padding: 6,
    fontSize: 18,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  imageDiv: {
    width: 150,
    height: 150,
    alignItems: "center",
    alignContent: "space-around",
    display: "flex",
  },
  "MuiButton-label": {
    color: "white",
  },
  linkHref: {
    color: "black",
    textDecoration: "none",
    textDecorationLine: "underline",
    backgroundColor: "none",
    marginVertical: 8,
  },
}));

export default function SimpleCard({
  data,
  showActions,
  showTags,
  picWidth,
  width,
  calendars,
}) {
  const classes = cardStyles();
  return (
    <Card className={classes.root} style={{ width: width }}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignContent: "space-between",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <Typography variant="subtitle1" color="textSecondary">
              {"Help Center"}
            </Typography>
          </div>

          <div>
            <a
              className={classes.linkHref}
              target="_blank"
              rel="noreferrer"
              href="https://tameemiftikhar.notion.site/CalSync-Guides-and-FAQs-73310edc66b74a11b1baf79e732d430e"
            >
              📚 All Guides & FAQ
            </a>
          </div>
          <div>
            <a
              className={classes.linkHref}
              target="_blank"
              rel="noreferrer"
              href="https://tameemiftikhar.notion.site/Connect-Calendar-c48d51c6604e4549b4f37cd8ee01b680"
            >
              👩‍💻 Connect Calendar
            </a>
          </div>
          <div>
            <a
              className={classes.linkHref}
              target="_blank"
              rel="noreferrer"
              href="https://tameemiftikhar.notion.site/Sync-Calendar-312ad68f88aa42ddbf9e00959538bea5"
            >
              👩‍💻 Sync Calendar
            </a>
          </div>
        </CardContent>
      </div>
    </Card>
  );
}
